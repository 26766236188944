import { apiClient } from 'utils/apiClient'
import { withLogger } from 'utils/Logger'
import { UserBase, UserRole, UserVerification } from 'shared/types/V3/User'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'

async function checkExistedUser(email: string, role?: UserRole, username?: string) {
  const params = new URLSearchParams()

  if (email) params.append('email', email)
  if (role) params.append('role', role)
  if (username) params.append('username', username)

  const requestURL = `/v2/onboarding/users/existed?${params.toString()}`

  const response = await apiClient.get<NewBackEndApiResponseData<{ user: UserBase & UserVerification }>>(requestURL)

  const { data } = response.data

  return data?.user
}

export default withLogger(checkExistedUser, {
  action: 'check existed user',
  customErrorText: 'Cannot check existed user',
})
